.dark-mode {
    @import "~@clr/ui/src/utils/theme.dark.clarity"; // Dark theme variables
    @import '~@clr/ui/src/utils/components.clarity';

    // @import third party styling...

    // Fix styling HTML-tag.
    // node_modules\@clr\ui\src\typography\_typography.clarity.scss
    & html {
        color: $clr-global-font-color;
        font-family: $clr-font;
        font-size: $clr-baseline-px;
    }
}