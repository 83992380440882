@import "../node_modules/@clr/icons/clr-icons.min.css";
@import "../node_modules/@clr/ui/clr-ui.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css";

@import "../node_modules/ngx-drag-to-select/ngx-drag-to-select.css";


:root {
  //Properties/Colors are set in Typescript --> app.component.ts
  --custom-color123: red;
  --endpoint-cards: red;
  --endpoint-cards-text: red;
  --container-header: #FFFFFF;
  --container-box-border-enabled: solid;
  --container-text: red;
  --content-area-padding: 10px;


  --ndp-brand-color: #0C1477;
  --ndp-light-brand-color: #E8F6FF;
  --ndp-success-color: #89BB61;
  --ndp-danger-color: #BB6161;


  --clr-card-border-radius: 6px;
  --clr-h3-color: var(--ndp-brand-color);
  --clr-link-color: var(--ndp-brand-color);
  --clr-h3-font-weight: 400;
  --clr-label-light-blue-bg-color: #89CBDF;
  --clr-badge-light-blue-bg-color: var(--clr-label-light-blue-bg-color);
  --clr-badge-light-blue-color: #000;

  --clr-login-background-color: #FFFFFF;
  --clr-table-font-color: #797979;
  --clr-global-app-background: #F9F9F9;

  --clr-p1-color: #565656;
  --clr-datagrid-icon-color: var(--ndp-brand-color);
  --clr-thead-bgcolor: var(--ndp-light-brand-color);

  // lifecycle colors
  --ndp-production-color: var(--ndp-brand-color);
  --ndp-acceptance-color: #0AA455;
  --ndp-testing-color: #FAC400;
  --ndp-production-light-color: #F1F1F5;
  --ndp-acceptance-light-color: #DFF0D0;
  --ndp-testing-light-color: #FEECB5;

  // Vertical nav
  --clr-vertical-nav-item-active-color: var(--ndp-brand-color);
  --clr-vertical-nav-icon-active-color: var(--ndp-brand-color);
  --clr-vertical-nav-item-color: var(--ndp-brand-color);
  --clr-vertical-nav-icon-color: var(--ndp-brand-color);
  --clr-vertical-nav-active-bg-color: var(--ndp-light-brand-color);
  --clr-vertical-nav-hover-bg-color: var(--ndp-light-brand-color);
  --clr-vertical-nav-bg-color: var(--clr-card-bg-color, white);
  --clr-vertical-nav-toggle-icon-color: var(--ndp-brand-color);

  // Header
  --clr-header-nav-opacity: 1;
  --clr-header-nav-hover-opacity: 0.65;
  --clr-global-content-header-font-color: var(--ndp-brand-color);
  --clr-h5-color: var(--ndp-brand-color);

  // button
  --clr-btn-primary-disabled-color: #8194A4;
  --clr-btn-primary-disabled-bg-color: #E5EAEE;
  --clr-btn-danger-disabled-bg-color: var(--clr-btn-primary-disabled-bg-color);
  --clr-btn-success-bg-color: var(--ndp-success-color);

  // Action color
  --clr-color-action-50: #e7e8f1;
  --clr-color-action-100: #b6b9d6;
  --clr-color-action-200: #868abb;
  --clr-color-action-300: #6d72ad;
  --clr-color-action-400: #555ba0;
  --clr-color-action-500: #3d4392;
  --clr-color-action-600: #0c1477;
  --clr-color-action-700: #080e53;
  --clr-color-action-800: #060a3c;
  --clr-color-action-900: #040624;
  --clr-color-action-1000: #01020c;

  // light mode 
  --container-box: #FAFAFA;
  --container-box-border: transparent;
  --item-box: var(--clr-btn-success-bg-color);
  --item-box-text: #666666;
  --container-header-text: (--ndp-brand-color);

  --item-box-border: #9FB3BF;
  --item-box-border-enabled: solid;

}

// Fonts - AmpleSoft

@font-face {
  font-family: 'AmpleSoft';
  src: url('assets/fonts/AmpleSoft.otf');
}

@font-face {
  font-family: 'AmpleSoft Medium';
  src: url('assets/fonts/AmpleSoft Medium.otf');
}

@font-face {
  font-family: 'AmpleSoft Bold';
  src: url('assets/fonts/AmpleSoft Bold.otf');
}

@font-face {
  font-family: 'AmpleSoft Thin';
  src: url('assets/fonts/AmpleSoft Thin.otf');
}

@font-face {
  font-family: 'AmpleSoft Light';
  src: url('assets/fonts/AmpleSoft Light.otf');
}

@font-face {
  font-family: 'AmpleSoft ExtraLight';
  src: url('assets/fonts/AmpleSoft ExtraLight.otf');
}

.mat-simple-snackbar-action {
  color: var(--ndp-light-brand-color) !important;
}

.mat-tooltip.sequenceCommand {
  font-size: small;
}

.cursor-pointer {
  cursor: pointer;
}

.clr-form-control {
  margin-top: 0;
}

.card {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.06) !important;
  border: none;
}

.card.schedule-card {
  box-shadow: none !important;
  border: 1px solid #CCCCCC;
  border-radius: 12px;
  transition: 0.5s;

  &.schedule-card-picked {
    border: 1px solid var(--ndp-brand-color);
  }
}

.card-header {
  background-color: #FFFFFF;
  border: none;
}

.card-footer {
  background-color: #FFFFFF;
  border: none;
  padding-top: 0;
}

.card-footer::before {
  content: "";
  display: block;
  background-color: #E0E0E0;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
}

.btn:disabled {
  opacity: 1 !important;
}

.btn-primary,
.btn-success,
.btn-warning,
.btn-danger {
  border: none;
}

.datagrid-spinner {
  top: 0;
  height: 100%;
}

.action-group {
  display: flex;
  align-items: center;
  gap: 3px;

  .btn:enabled img {
    filter: brightness(200%) contrast(200%);
  }

  .btn {
    margin: 0;
  }
}

.table-container-card {

  height: 75vh;

  .datagrid-select .clr-checkbox-wrapper input[type="checkbox"] {

    +label::before,
    +label::after {
      top: 0 !important;
    }
  }

  .table-heading {
    padding: 1em 2em 2em 2em;
    display: flex;
    justify-content: space-between;

    .clr-control-label {
      color: var(--ndp-brand-color);
    }

    .btn {
      margin: 0;
    }

    .btn-group {
      gap: 2px;

      .btn:enabled img {
        filter: brightness(200%) contrast(200%);
      }
    }

    .table-title {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      >* {
        margin: 0;
      }

      img {
        height: 24px;
      }
    }
  }

  .datagrid {
    border: none;
    height: 100%;
  }

  .datagrid-header {
    background-color: var(--ndp-light-brand-color);

    .datagrid-row {
      border: none;
    }

    .datagrid-column {
      background-color: var(--ndp-light-brand-color);
    }
  }

  .datagrid-detail-open {
    .datagrid-row {
      border-right: none;
    }

    .datagrid-placeholder-container {
      border-right: none;
    }

    .datagrid-detail-pane {
      margin-top: 0;
      border-left: var(--clr-datagrid-default-border-color) 1px solid;
    }
  }

  .datagrid-detail-header .datagrid-detail-header-title {
    width: 100%;
  }

  .datagrid-cell,
  .datagrid-footer {
    padding: 10px 12px !important;
  }

  .datagrid-header {
    padding: 10px 0px;
  }

  .datagrid-column-title {
    color: var(--ndp-brand-color) !important;
  }

  .datagrid-column-separator::after {
    background-color: #DAEDFA !important;
  }

  .datagrid-column-separator:first-child::after {
    content: none;
  }

  .datagrid-cell:last-child::after {
    content: none;
  }

  .datagrid-footer {
    border-style: none;
    background-color: var(--ndp-light-brand-color);
    color: var(--ndp-brand-color);
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .datagrid-cell,
  .datagrid-select {
    display: flex;
    align-items: center;
  }
}

.draggable-item {
  &.item-box {
    &:hover {
      background: var(--ndp-success-color);
      color: #FFFFFF;
    }

    button,
    button:hover {
      background-color: var(--ndp-success-color);
    }
  }

  &.selected-item {
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;

    button {
      background-color: var(--ndp-danger-color);

      &:hover {
        background-color: var(--ndp-danger-color);
      }
    }

    .item-actions {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .tags {
      display: flex;
      align-items: center;
      gap: 1px;
    }

    .selected-item-details {
      margin-left: 1em;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
  }

  &.selected-item:not(.cdk-drag-placeholder):hover {
    background-color: var(--ndp-danger-color);
    color: #FFFFFF;

    .action-settings {
      filter: none;
    }
  }

  &.item-box,
  &.selected-item {
    margin-bottom: 6px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    cursor: grab;
    border-color: var(--item-box-border);
    border-style: var(--item-box-border-enabled);
    border-width: thin;
    color: var(--item-box-text);
    background: #FFFFFF;
    font-size: 14px;
    border-radius: 5px;
    min-height: 2rem;

    .item-name {
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    button {
      margin: 0;
      padding: 0rem 0.4rem;
      border-radius: 0;
      border: none;
    }

    cds-icon {
      color: #FFFFFF;
    }
  }
}

.details-container-card {
  padding: 25px;
  height: 90%;

  .details-title {
    display: flex;
    align-items: center;
    gap: 0.5em;
    width: 100%;

    h5 {
      margin-top: 0;
    }

    .add-blueprint {
      align-self: end;
      margin-left: 1em;
    }
  }

  .main-container {
    max-height: 85%;
    background-color: transparent;
    max-width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 1rem;

    >div {
      height: 100%;
    }

    .section-heading {
      background-color: var(--ndp-light-brand-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      color: var(--ndp-brand-color);
      font-size: 0.7rem;
      border-radius: 5px 5px 0 0;

      .btn img {
        width: 15px;
      }

      .btn-primary {
        background-color: #83A6BD;

        &:hover {
          background-color: #5c7484;
        }
      }

      .filter {
        display: flex;
        gap: 10px;
        align-items: center;
        text-align: left;

        >div {
          white-space: nowrap;
        }

        input {
          font-size: 0.6rem;
        }
      }
    }

    .scroll-container {
      border: none !important;
      border-radius: 0 0 5px 5px;
      overflow-x: hidden;
      overflow-y: auto;
      height: 90%;
      width: 100%;

      background-color: var(--container-box);
      border-color: var(--container-box-border);
      border-style: var(--container-box-border-enabled);
      padding-top: 20px;

      >div:not(.section-heading) {
        padding: 0 20px;
      }

      p {
        color: #6b6b6b;
      }

      .action-setings {
        filter: brightness(0) saturate(100%) invert(80%) sepia(15%) saturate(290%) hue-rotate(159deg) brightness(85%) contrast(94%);
      }

      .cdk-drag-placeholder {
        opacity: 0.5;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .item-list.cdk-drop-list-dragging .item-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .item-list {
        min-height: 50%;
      }
    }

    .item-box:hover {
      background: var(--ndp-success-color);
      color: white;

      .cdk-drag-placeholder {
        opacity: 0.5;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .item-list.cdk-drop-list-dragging .item-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 1rem;

    button {
      padding: 0 2rem;
    }
  }
}

.login-wrapper .login::after {
  content: none;
}

.datagrid {
  margin-top: 0rem !important;
}

#left-menu .nav-content {
  padding-top: 0 !important;
}

.content-area {
  padding: var(--content-area-padding) !important; //Has to be 0 for login screen
}

.login-wrapper {
  background-image: url("/assets/login-hero.svg") !important;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 70% 50%;
}

.clr-wizard--inline {
  animation-duration: 0 !important;

  .modal {
    .modal-dialog {
      height: 80vh;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }

  .modal-content {
    border: none;
  }
}


//Scrollbar dankzij: https://github.com/IgorMinar 
//https://github.com/angular/angular/blob/3a30f5d937e64289ad9a89a1cbc4bd66d8a8867a/aio/src/styles/2-modules/_scrollbar.scss
*::-webkit-scrollbar,
*::-webkit-scrollbar,
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.048);
}

*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid rgba(51, 51, 51, 0.253);
  // border-bottom-right-radius: 5px;
}

html,
body {
  // min-height: 101%;

  // max-height: 100%; 
  height: 100%;
  // max-width: 100% !important;
  // overlow: hidden !important;
  background-color: transparent;
}

body {
  background-color: transparent;
}

.datagrid-filter {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  gap: 0.5rem !important;

  .datagrid-filter-close-wrapper {
    line-height: 0;
  }
}

.skeleton {
  height: 12rem;
  border-radius: 0.5rem;
  background: linear-gradient(45deg, #eee 40%, #fafafa 50%, #eee 60%);
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes shimmer {
  to {
    background-position-x: 0%
  }
}